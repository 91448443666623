import { saveAs } from 'file-saver'
import axios from 'axios'
// import {getToken} from '@/libs/util'
import { Message } from 'element-ui'
// import $config from '@/config'

// const baseURL = process.env.VUE_APP_BUILD === 'pro' ? $config.apiUrl.pro : $config.apiUrl.dev

export default {
  //下载督办任务文件
  sufile(href,token) {
    var url = "https://api.baiyatech.cn" + href;
    axios({
      method: 'get',
      url: url,
      responseType: 'blob',
      headers: { 'Authorization': 'Bearer ' + token }
    }).then(async (res) => {
      const isLogin = await this.blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"  //type设置文件下载类型
        })
          console.log('name',decodeURI(res.headers['content-disposition'].split(';')[1].split('filename=')[1])) //文件名
        this.saveAs(blob, decodeURI(res.headers['content-disposition'].split(';')[1].split('filename=')[1])) //
      } else {
        Message.error('无效的会话，或者会话已过期，请重新登录。');
      }
    })
  },
  // name(name, isDelete = true) {
  //   var url = baseURL + "/common/download?fileName=" + encodeURI(name) + "&delete=" + isDelete
  //   axios({
  //     method: 'get',
  //     url: url,
  //     responseType: 'blob',
  //     headers: { 'Authorization': 'Bearer ' + getToken() }
  //   }).then(async (res) => {
  //     const isLogin = await this.blobValidate(res.data);
  //     if (isLogin) {
  //       const blob = new Blob([res.data])
  //       this.saveAs(blob, decodeURI(res.headers['download-filename']))
  //     } else {
  //       Message.error('无效的会话，或者会话已过期，请重新登录。');
  //     }
  //   })
  // },
  // resource(resource) {
  //   var url = baseURL + "/common/download/resource?resource=" + encodeURI(resource);
  //   axios({
  //     method: 'get',
  //     url: url,
  //     responseType: 'blob',
  //     headers: { 'Authorization': 'Bearer ' + getToken() }
  //   }).then(async (res) => {
  //     const isLogin = await this.blobValidate(res.data);
  //     if (isLogin) {
  //       const blob = new Blob([res.data])
  //       this.saveAs(blob, decodeURI(res.headers['download-filename']))
  //     } else {
  //       Message.error('无效的会话，或者会话已过期，请重新登录。');
  //     }
  //   })
  // },
  // zip(url, name) {
  //   var url = baseURL + url
  //   axios({
  //     method: 'get',
  //     url: url,
  //     responseType: 'blob',
  //     headers: { 'Authorization': 'Bearer ' + getToken() }
  //   }).then(async(res) => {
  //     const isLogin = await this.blobValidate(res.data)
  //     if (isLogin) {
  //       const blob = new Blob([res.data], { type: 'application/zip' })
  //       this.saveAs(blob, name)
  //     } else {
  //       Message.error('无效的会话，或者会话已过期，请重新登录。')
  //     }
  //   })
  // },
  saveAs(text, name, opts) {

    saveAs(text, name, opts)
  },
  async blobValidate(data) {
    try {
      const text = await data.text()
      JSON.parse(text)
      return false
    } catch (error) {
      return true
    }
  }
}

