import request from '../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)

//根据查询条件获取房间列表
export function roomList(hotelId, floorNumber,roomName,roomNumber,templateId, limit,page,token) {
    return request({
        url:"/hotel/room/getRoomList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            floorNumber:floorNumber,
            roomName:roomName,
            roomNumber:roomNumber,
            templateId:templateId,
            limit:limit,
            page:page,
        }
    })
}

//查询房间模板列表
export function getTempList(hotelId,token){
    return request({
        url:"/hotel/template/getTemplateList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
        }
    })
}

//查询施工人员列表
export function getInstallerList(hotelId,token){
    return request({
        url:"/hotel/user/installer/list",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
        }
    })
}

//新增房间
export function addRoom(data,token){
    return request({
        url:"/hotel/room/addRoom",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}

//获取房间详情
export function RoomDetail(roomId,token){
    return request({
        url:"/hotel/room/getRoomDetails",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            roomId:roomId
        }
    })
}
//修改房间
export function editRoom(data,token){
    return request({
        url:"/hotel/room/updateRoom",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//删除房间
export function delRoom(roomId,token){
    return request({
        url:"/hotel/room/removeRoom",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            roomId:roomId
        }
    })
}
//查询房间设备
export function RoomDevice(roomId,token){
    return request({
        url:"/hotel/room/device/getTemplateDeviceList",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            roomId:roomId
        }
    })
}